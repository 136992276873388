<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms">
      <validation-observer v-slot="{handleSubmit}" ref="doctorFormValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Title"
                  label="Title*" v-model="request.doctorForm.title"
                  placeholder="Title">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Name"
                  label="Name*" v-model="request.doctorForm.name"
                  placeholder="Name">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  type="email"
                  :rules="{required: true}"
                  name="Email"
                  label="Email*" v-model="request.doctorForm.email"
                  placeholder="Email">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  @input="acceptNumber"
                  type="tel"
                  name="Phone"
                  label="Phone*" v-model="request.doctorForm.phone"
                  placeholder="123-456-7890">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Address"
                  label="Address*" v-model="request.doctorForm.address"
                  placeholder="Address">
                </base-input>

              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="City"
                  label="City & State*" v-model="request.doctorForm.city"
                  placeholder="Austin, TX">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  name="Zip Code"
                  label="Zip Code"
                  :maxlength="12"
                  :value="request.doctorForm.zipCode"
                  @input="handleZipInput"
                  placeholder="Zip Code">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center mb-3">
                <div class="img-upload"
                     :class="{'w-100':!imageExist}">
                  <label class="form-control-label" for="profileImage">Profile Image</label>
                  <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                         class="form-control mb-1" @change="onFileChange">
                  <span class="font-size-12px">Recommended image size 500x500 px. Must be less than 500kb.</span>
                </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2"
                  :class="{'d-none':!imageExist}"
                  :src="request.doctorForm.imagePreview"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  name="Hospital Name"
                  label="Practice/Hospital Name" v-model="request.doctorForm.hospitalName"
                  placeholder="Hospital Name">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input label="Status*">
                  <el-select
                    class="w-100"
                    label="Status*"
                    v-model="request.doctorForm.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col equal-height-columns">
                <label >About</label>
                <editor
                  v-model="request.doctorForm.about"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('doctorForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import Helper from '../../../../util/globalHelpers'
import {store} from "@/vuex/store";

export default {
  name: "DoctorForm",
  props: ['doctorItemToEdit', 'loaders', 'buttonText'],
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        loaders:{
          image: false,
        },
        doctorForm: {
          name: '',
          email: '',
          title: '',
          image: '',
          imagePreview: '',
          phone: '',
          address: '',
          zipCode: '',
          city: '',
          hospitalName: '',
          status: 1,
          about: '',
        },
      },
      response: {
      },
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    }
  },
  mounted() {

  },
  watch: {
    doctorItemToEdit(newVal) {
      this.request.doctorForm.name = newVal.name ?? '';
      this.request.doctorForm.email = newVal.email ?? '';
      this.request.doctorForm.title = newVal.title ?? '';
      this.request.doctorForm.phone = newVal.phone ?? '';
      this.request.doctorForm.address = newVal.address ?? '';
      this.request.doctorForm.zipCode = newVal.pin_code ?? '';
      this.request.doctorForm.city = newVal.city ?? '';
      this.request.doctorForm.hospitalName = newVal.practice ?? '';
      this.request.doctorForm.about = newVal.about ?? '';
      this.request.doctorForm.status = newVal.status ?? '';
      this.request.doctorForm.imagePreview = newVal.profile_image ?? ''
    }
  },
  methods: {
    /**
     * Accept and format number in 999-999-9999
     */
    acceptNumber() {
      this.request.doctorForm.phone = Helper.formatPhoneNumber(this.request.doctorForm.phone)
    },

    onFileChange(e) {
      this.request.doctorForm.image = e.target.files[0];
    },

    handleZipInput(e) {
      if (Helper.isAlphaNumeric(e)) this.request.doctorForm.zipCode = e
      else if(e==='')this.request.doctorForm.zipCode =''
    },

    handleKeyPressForEditor(value) {
      this.request.doctorForm.about = value;
    },

    async onSubmit(formName) {
      const isDoctorFormValid = await this.$refs['doctorFormValidator'].validate();

      if (isDoctorFormValid) {
        this.request.doctorForm.about = Helper.addCustomStylingToContent(this.request.doctorForm.about)
        if (this.$route.name === 'StoreDoctor') {
          // console.log('store', this.request.doctorForm);
          this.$emit('storeDoctor', this.request.doctorForm);
        } else if (this.$route.name === 'EditDoctor') {
          // console.log('edit', this.request.doctorForm);
          this.$emit('updateDoctor', this.request.doctorForm);
        } else {
          // console.log('Hello Else World');
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  computed: {
    imageExist() {
      return this.request.doctorForm.imagePreview !== ''
        && this.request.doctorForm.imagePreview
        && this.request.doctorForm.image === ''
    }
  },
}
</script>

<style scoped>

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}

</style>
