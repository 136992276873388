<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Doctor</h1>
    </div>
    <doctor-form v-loading="loaders.forms"
      button-text="Update"
      :doctor-item-to-edit="response.doctor"
      @updateDoctor="updateDoctor"
    />
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import DoctorForm from "./DoctorForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
import swalFireMixin from "../../../../mixins/swalFireMixin";

export default {
  name: "EditDoctor",
  components: {BackButton,DoctorForm},
  data() {
    return {
      request: {
        id: this.$route.params.id,
      },
      response: {
        doctor: {},
        categories: [],
      },
      loaders: {
        forms: false,
      },
    }
  },
  mixins: [
    generateErrorMessageMixin,
    swalFireMixin
  ],
  methods: {
    getDoctor() {
      let vm = this;
      vm.loaders.forms = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/doctors/' + this.request.id).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.doctor = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false
      });
    },

    updateDoctor(event) {
      let vm = this;
      vm.loaders.forms = true;
      var formData = new FormData();
      formData.append('id', this.request.id);
      _.each(event, (value, key) => {
        formData.append(key, value);
      });
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };
      axios.post(this.$store.getters.getBaseUrl + `/api/content/doctors/update`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.showContentUpdateAlert()
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false
      });
    }
  },
  mounted() {
    this.getDoctor();
  }
}
</script>

<style scoped>

</style>

<style>

</style>

