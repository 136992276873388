<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Doctor</h1>
    </div>
    <doctor-form v-loading="loaders.doctor"
      button-text="Save"
      @storeDoctor="storeDoctor"
    />
  </div>
</template>

<script>
import DoctorForm from "./DoctorForm";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
export default {
  components :{
    DoctorForm,BackButton
  },
  name: "StoreDoctor",
  mounted() {

  },
  data() {
    return {
      loaders: {
       doctor : false,
      },
      response: {
      },
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    storeDoctor(event) {
      let vm = this;
      vm.loaders.doctor = true;
      var formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/doctors/store`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Doctor created successfully.'
        });
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Doctors',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => {
        vm.loaders.doctor = false;
      });
    },
  },

}
</script>

<style scoped>

</style>
